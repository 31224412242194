<template>
  <div class="new_list" v-loading="loading" element-loading-text="拼命加载中">
    <div class="container">
      <div class="breadcrumb">
        <span class="name">当前位置：</span>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">政策要闻</el-breadcrumb-item>
          <el-breadcrumb-item>残疾人风采</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1><img src="@/static/news/people.png" alt="" class="icon img">残疾人风采</h1>
      <div class="content flex">
        <div class="left">
          <ul class="list">
            <li class="box" v-for="(n,i) in list" :key="i" @click="goRouter('/news/newDetail',n.id)">
              <h2 class="line_clamp2"><a href="javascript:void(0);"
                                         @click="goRouter('/news/newDetail',n.id)">{{ n.title }}</a></h2>
              <div class="bottom flex">
                <img :src="n.thumbnail" alt="" class="banner err_image" v-if="n.thumbnail">
                <div class="info relative">
                  <div class="text line_clamp2">
                    {{ n.conAbstract }}
                  </div>
                  <div class="time">
                    {{ n.createTime }}
                    <span>{{ n.titleAbbr }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="pages">
            <el-pagination
                background
                class="work_pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.pageNum"
                :page-size="page.pageSize"
                layout="prev, pager, next, total, jumper"
                v-show="page.total > 10"
                :total="page.total">
            </el-pagination>
          </div>
        </div>
        <div class="right people">
          <div class="box">
            <div class="title">
              <h2>新闻动态</h2>
              <i class="icon icon el-icon-d-arrow-right" @click="goRouter('/news/new_list')"></i>
            </div>
            <ul>
              <li class="first flex">
                <div class="info" v-for="(n,i) in list1.slice(0,2)" :key="i">
                  <img :src="n.thumbnail" alt :title="n.title" class="banner err_image">
                  <a href="javascript:void(0);" :title="n.title" class="line_clamp1"
                     @click="goRouter('/news/newDetail',n.id)">{{ n.title }}</a>
                </div>
              </li>
              <li v-for="(n,i) in list1.slice(2,8)" :key="i" class="li line_clamp1">
                <a href="javascript:void(0);" :title="n.title" @click="goRouter('/news/newDetail',n.id)">
                  <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
          <div class="box">
            <div class="title">
              <h2>政策法规</h2>
              <i class="icon icon el-icon-d-arrow-right" @click="goRouter('/news/msg_list')"></i>
            </div>
            <ul>
              <li v-for="(n,i) in list2" :key="i" class="li line_clamp1">
                <a href="javascript:void(0);" :title="n.title" @click="goRouter('/news/newDetail',n.id)">
                  <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
          <div class="box">
            <div class="title">
              <h2>通知公告</h2>
              <i class="icon icon el-icon-d-arrow-right" @click="goRouter('/news/msg_list')"></i>
            </div>
            <ul>
              <li v-for="(n,i) in list3" :key="i" class="li line_clamp1">
                <a href="javascript:void(0);" :title="n.title" @click="goRouter('/news/newDetail',n.id)">
                  <span v-show="n.author">「{{ n.author }}」</span>{{ n.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "people_list",
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      list3: [],
      adList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        title: '',
        city: '兰州市',
        leve: '本市',
        consultationClassName: '人物故事',
      },
      cityType:'',
    }
  },
  methods: {
    //残疾人风采
    styles() {
      this.loading = true;
      this.$api.newsListApi(this.page).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    //新闻
    news() {
      this.$api.newsListApi({
        pageNum: 1,
        pageSize: 10,
        city: this.page.city,
        leve: this.page.leve,
        consultationClassName: this.cityType+'新闻动态',
      }).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list1 = res.data.data.records;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    //政策
    policy() {
      this.$api.newsListApi({
        pageNum: 1,
        pageSize: 10,
        city: this.page.city,
        leve: this.page.leve,
        consultationClassName: this.cityType+'政策法规',
      }).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list2 = res.data.data.records;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    //通知
    message() {
      this.$api.newsListApi({
        pageNum: 1,
        pageSize: 10,
        city: this.page.city,
        leve: this.page.leve,
        consultationClassName: this.cityType+'通知公告',
      }).then(res => {
        this.loading = false;
        if (res.data.success) {
          this.list3 = res.data.data.records;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id
        }
      })
      window.open(href, '_blank')
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.styles();
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.locationSite = localStorage.getItem('locationSite');
    if (this.locationSite === '陕西省') {
      this.cityType = '陕西';
    }else if (this.locationSite === '甘肃省'){
      this.cityType = '兰州';
    }
    this.styles();
    this.news();
    this.policy();
    this.message();
  },
  mounted() {
    this.$emit('goRouter', '/news/')
  }
}
</script>

<style scoped lang="less">
@import "../../assets/css/news.css";
</style>
